import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, makeStyles, Paper, Typography } from '@material-ui/core'
import { constants } from '../../global/constants'

const useStyles = makeStyles({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
})

const Copyright = () => {
	return (
		<Typography variant='body2' color='textPrimary' align='center'>
			{new Date().getFullYear()} {'© '}
			<Link to='/'>{constants.urlName}</Link>
		</Typography>
	)
}

export default function Footer() {
	const classes = useStyles()

	return (
		<Paper
			variant='outlined'
			style={{
				minHeight: '4rem',
				display: 'flex',
				alignItems: 'center',
				marginBottom: '0',
				marginTop: 'auto',
			}}
		>
			<Container className={classes.container}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img
						loading='lazy'
						style={{ height: '40px', transform: 'translate(0%, -10%)' }}
						src={process.env.PUBLIC_URL + '/thick-black-outline.png'}
						alt='logo'
					/>
					<div style={{ width: '1rem' }}></div>
					<Copyright />
				</div>
				<div>
					<Button href={constants.appUrl}>App</Button>

					<Button component={Link} to='/contact'>
						Support
					</Button>
				</div>
			</Container>
		</Paper>
	)
}
