import { Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import SortYourInvSectionCard from './SortYourInvSectionCard'
import tileImageLeft from '../../../assets/images/privacy-glyph.webp'
import tileImageMid from '../../../assets/images/live-tracking-glyph.webp'
import tileImageRight from '../../../assets/images/team-glyph.webp'

const useStyles = makeStyles({
	superContainer: {
		width: '100%',
		minHeight: '100px',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		padding: '48px 0px',
	},
	headlineTop: {
		color: '#282828',
		fontSize: '18px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '500',
	},
	headlineBtm: {
		color: '#282828',
		fontSize: '48px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '700',
	},
	columnContainer: {
		display: 'inline-flex',
		justifyContent: 'space-around',
		flexWrap: 'wrap',
	},
})

const cardData = [
	{ img: tileImageLeft, textTop: 'Privacy', textBtm: 'Protection' },
	{ img: tileImageMid, textTop: 'Real Time', textBtm: 'Updating' },
	{ img: tileImageRight, textTop: 'Team', textBtm: 'Collaboration' },
]

export default function SortYourInventory() {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<Typography className={classes.headlineTop}>Keep track of your stock</Typography>
			<Typography className={classes.headlineBtm}>Securely</Typography>
			<Container className={classes.columnContainer}>
				{cardData.map((data) => (
					<SortYourInvSectionCard key={data.textTop} data={data} />
				))}
			</Container>
		</div>
	)
}
