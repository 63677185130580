import { Helmet } from 'react-helmet'
import Features from '../components/Sections/Features/Features'
import Hero from '../components/Sections/Hero/Hero'
import SortYourInventory from '../components/Sections/SortYourInventory/SortYourInventory'
import TakeAdvantageOfYourData from '../components/Sections/TakeAdvantageOfYourData/TakeAdvantageOfYourData'

export default function Home() {
	return (
		<main>
			<Helmet>
				{/* HELMET USING UNSAFE_componentWillUnmount */}
				<title>Duffelbag | Home</title>
				<script type='text/babel' src='../global/ga.js' />
			</Helmet>

			<Hero />
			<SortYourInventory />
			<TakeAdvantageOfYourData />
			<Features />
		</main>
	)
}
