import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { constants } from '../../../global/constants'

const useStyles = makeStyles((theme) => ({
	superContainer: {
		minHeight: '270px',
		minWidth: '200px',
		margin: '12px',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	textContainer: {
		height: '200px',
		minWidth: '200px',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	topText: {
		fontFamily: "'Poppins', sans-serif",
		fontSize: '24px',
		fontWeight: '300',
		fontStyle: 'normal',
	},
	midText: {
		color: '#FFFFFF',
		fontFamily: "'Poppins', sans-serif",
		fontSize: '61px',
		fontWeight: '700',
		fontStyle: 'normal',
	},
	btmText: {
		color: 'white',
		fontFamily: "'Poppins', sans-serif",
		fontSize: '16px',
		fontWeight: '300',
		fontStyle: 'normal',
	},
	btn: {
		minHeight: '50px',
		width: '200px',
	},
}))

export const LeftSection = () => {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<div className={classes.textContainer}>
				<Typography className={classes.topText}>Track all your inventory in</Typography>
				<Typography className={classes.midText}>One Place</Typography>
				<Typography className={classes.btmText}>from any device</Typography>
				<br />
				<Button
					href={constants.signInUrl}
					className={classes.btn}
					disableElevation
					variant='contained'
					color='primary'
				>
					Start For Free
				</Button>
			</div>
		</div>
	)
}
