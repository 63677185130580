import { makeStyles } from '@material-ui/core'
import { constants } from '../../global/constants'

const useStyles = makeStyles((theme) => ({
	container: {
		height: `${constants.navbarHeight + 40}px`,
		width: '100%',
		// backgroundColor: 'blue',
		margin: 0,
		padding: 0,
	},
}))

export default function NavbarBuffer() {
	const classes = useStyles()

	return <div className={classes.container}></div>
}
