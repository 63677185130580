import React from 'react'
import { AppBar, Toolbar, Typography, Button, Container, makeStyles } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { constants } from '../../global/constants'
import { useMediaQuery } from '@material-ui/core'
import NavagationDropdown from './NavagationDropdown'

const useStyles = makeStyles(() => ({
	appbar: { backgroundColor: 'transparent', height: constants.navbarHeight },
	toolbar: { width: '100%' },
	superContainer: { display: 'flex', justifyContent: 'space-between' },
	buttonGroupContainer: {},
	primaryButton: {
		marginRight: '3rem',
		marginLeft: '3rem',
		minWidth: '120px',
	},
	secondaryButton: {
		minWidth: '120px',
	},
}))

export default function Navbar({ root }) {
	const classes = useStyles()
	const location = useLocation().pathname
	const isSmall = useMediaQuery('(max-width:920px)')

	const [navColor, setNavColor] = React.useState('rgb(255,255,255,0)')
	const [navMargin, setNavMargin] = React.useState(40)

	React.useEffect(() => {
		const heightChanged = () => {
			const dist = document.documentElement.scrollTop
			setNavColor(`rgb(255,255,255,${(dist - 40) / 100})`)

			setNavMargin(dist < 40 ? 40 - dist : 0)
		}

		window.addEventListener('scroll', heightChanged)
		heightChanged()

		return () => window.removeEventListener('scroll', heightChanged)
	})

	return (
		<React.Fragment>
			<AppBar
				id='AppBar'
				position='fixed'
				elevation={0}
				className={classes.appbar}
				style={{ backgroundColor: navColor, marginTop: navMargin }}
			>
				<Toolbar className={classes.toolbar}>
					<Container className={classes.superContainer} maxWidth='lg'>
						<a
							href={'/'}
							style={{
								cursor: 'pointer',
								textDecoration: 'none',
								display: 'inline-flex',
							}}
						>
							<img
								loading='lazy'
								style={{ height: '30px' }}
								src={process.env.PUBLIC_URL + '/thick-black-outline.png'}
								alt='logo'
							/>
							<Typography
								variant='overline'
								style={{
									marginLeft: '0.5rem',
									fontSize: '1rem',
									color: 'black',
								}}
							>
								Duffelbag
							</Typography>
						</a>

						{isSmall ? (
							<NavagationDropdown />
						) : (
							<div className={classes.buttonGroupContainer}>
								{location === '/' ? null : (
									<Button
										className={classes.secondaryButton}
										component={Link}
										to='/'
									>
										Home
									</Button>
								)}
								{location === '/about' ? null : (
									<Button
										className={classes.secondaryButton}
										component={Link}
										to='/about'
									>
										About
									</Button>
								)}
								{location === '/pricing' ? null : (
									<Button
										className={classes.secondaryButton}
										component={Link}
										to='/pricing'
									>
										Pricing
									</Button>
								)}
								{location === '/install' ? null : (
									<Button
										className={classes.secondaryButton}
										component={Link}
										to='/install'
									>
										Install
									</Button>
								)}
								{location === '/contact' ? null : (
									<Button
										className={classes.secondaryButton}
										component={Link}
										to='/contact'
									>
										Contact
									</Button>
								)}
								<Button
									className={classes.primaryButton}
									href={constants.signInUrl}
									variant='contained'
									color='primary'
									disableElevation
								>
									Launch
								</Button>
							</div>
						)}
					</Container>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	)
}
