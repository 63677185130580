import { Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import SortYourInvSectionCard from './FeatureCard'
import img1 from '../../../assets/images/live-support-glyph.webp'
import img2 from '../../../assets/images/easy-to-use-glyph.webp'
import img3 from '../../../assets/images/customise-settings-glyph.webp'
import img4 from '../../../assets/images/import-data-glyph.webp'
import img5 from '../../../assets/images/mobile-friendly-glyph.webp'
import img6 from '../../../assets/images/secure-glyph.webp'
import bgPattern from '../../../assets/images/bg-pattern.webp'

const useStyles = makeStyles({
	superContainer: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		padding: '48px 0px',
	},
	headlineTop: {
		color: '#282828',
		fontSize: '18px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '500',
	},
	headlineBtm: {
		color: '#282828',
		fontSize: '48px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '700',
	},
	columnContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		maxWidth: '1200px',
	},
	img: {
		maxWidth: '300px',
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: -1,
		opacity: 0.3,
	},
})

const cardData = [
	{
		img: img1,
		textTop: 'Live Support',
		textBtm: 'Get live help when you need it most.',
	},
	{
		img: img2,
		textTop: 'Intuitive UI',
		textBtm: 'A user interface that is easy to understand.',
	},
	{
		img: img3,
		textTop: 'Custom Settings',
		textBtm: 'Customise the UI by choosing the theme you prefer.',
	},
	{
		img: img4,
		textTop: 'Import Data',
		textBtm: 'Import your data from Excel.',
	},
	{
		img: img5,
		textTop: 'Mobile Friendly',
		textBtm: 'Install Duffelbag on any device.',
	},
	{
		img: img6,
		textTop: 'Secure',
		textBtm: 'Duffelbag uses world class security to protect your data.',
	},
]

export default function Features() {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<img loading='lazy' src={bgPattern} className={classes.img} alt='features decoration' />

			<Typography className={classes.headlineTop}>Extensive</Typography>
			<Typography className={classes.headlineBtm}>Features</Typography>
			<Container className={classes.columnContainer}>
				{cardData.map((data) => (
					<SortYourInvSectionCard key={data.textTop} data={data} />
				))}
			</Container>
		</div>
	)
}
