import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
	card: {
		// minWidth: '200px',
		maxWidth: 'fit-content',
		minHeight: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '48px',
	},
	img: {
		maxWidth: '200px',
	},
	textTop: {
		marginTop: '36px',
		color: '#282828',
		fontSize: '18px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '500',
	},
	textBtm: {
		marginTop: '8px',
		marginBottom: '24px',
		color: '#282828',
		fontSize: '27px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '600',
	},
})

export default function SortYourInvSectionCard({ data }) {
	const classes = useStyles()

	const { img, textTop, textBtm } = data

	return (
		<Container className={classes.card} elevation={0}>
			<img
				loading='lazy'
				src={img}
				className={classes.img}
				alt={`Feature card - ${textTop}`}
			/>
			<Typography className={classes.textTop}>{textTop}</Typography>
			<Typography className={classes.textBtm}>{textBtm}</Typography>
		</Container>
	)
}
