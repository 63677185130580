import React from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useLocation } from 'react-router-dom'
import { constants } from '../../global/constants'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	paper: {
		marginRight: theme.spacing(2),
	},
}))

export default function NavagationDropdown() {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const location = useLocation().pathname

	const handleToggle = () => setOpen((prevOpen) => !prevOpen)

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setOpen(false)
	}

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open)
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		}

		prevOpen.current = open
	}, [open])

	return (
		<div>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-label='Toggle Menu'
				aria-haspopup='true'
				onClick={handleToggle}
			>
				<MenuRoundedIcon fontSize={'large'} />
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper style={{ minWidth: '150px' }}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id='menu-list-grow'
									onKeyDown={handleListKeyDown}
								>
									{location === '/' ? null : (
										<MenuItem
											className={classes.secondaryButton}
											component={Link}
											onClick={handleClose}
											to='/'
										>
											Home
										</MenuItem>
									)}
									{location === '/about' ? null : (
										<MenuItem
											className={classes.secondaryButton}
											component={Link}
											onClick={handleClose}
											to='/about'
										>
											About
										</MenuItem>
									)}
									{location === '/pricing' ? null : (
										<MenuItem
											className={classes.secondaryButton}
											component={Link}
											onClick={handleClose}
											to='/pricing'
										>
											Pricing
										</MenuItem>
									)}
									{location === '/install' ? null : (
										<MenuItem
											className={classes.secondaryButton}
											component={Link}
											onClick={handleClose}
											to='/install'
										>
											Install
										</MenuItem>
									)}
									{location === '/contact' ? null : (
										<MenuItem
											className={classes.secondaryButton}
											component={Link}
											onClick={handleClose}
											to='/contact'
										>
											Contact
										</MenuItem>
									)}
									<MenuItem
										className={classes.primaryButton}
										href={constants.signInUrl}
										variant='contained'
										color='primary'
										disableElevation
									>
										Launch
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}
