import React from 'react'
import { makeStyles } from '@material-ui/core'
import DashboardView from '../../../assets/images/macbook-dashboard.webp'
import PhoneSample from '../../../assets/images/phone-sample.webp'
import './yt-player.css'

const useStyles = makeStyles({
	superContainer: {
		position: 'relative',
		margin: '12px',
		flexGrow: '1',
		textAlign: 'center',
		maxWidth: '700px',
	},
	macbook: {
		maxWidth: '100%',
	},
	phoneSample: {
		position: 'absolute',
		maxHeight: '61%',
		right: '-5%',
		bottom: '11%',
	},
})

export const RightSection = () => {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<iframe
				style={{
					position: 'absolute',
					height: '76%',
					width: '71%',
					bottom: '15%',
					left: '14.5%',
				}}
				src='https://www.youtube.com/embed/gX3mdpsUXDw?modestbranding=1&rel=0&iv_load_policy=3&fs=0&disablekb=1&showinfo=0&autoplay=1'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
			<img
				loading='lazy'
				src={DashboardView}
				className={classes.macbook}
				alt='Example on laptop'
			/>
			<img
				loading='lazy'
				src={PhoneSample}
				className={classes.phoneSample}
				alt='Example on phone'
			/>
		</div>
	)
}
