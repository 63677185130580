export const constants = {
	navbarHeight: 65,
	mainUrl:
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://duffelbag.io',
	appUrl:
		process.env.NODE_ENV === 'development'
			? 'http://localhost:3001'
			: 'https://app.duffelbag.io',
	signInUrl:
		process.env.NODE_ENV === 'development'
			? 'http://localhost:3001/signin'
			: 'https://app.duffelbag.io/signin',
	appName: 'Duffelbag',
	urlName: 'duffelbag.io',
	supportEmail: 'support@duffelbag.io',
}
