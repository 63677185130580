import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Background from '../../../assets/images/gradient.webp'
import NavbarBuffer from '../../Navbar/NavbarBuffer'
import { LeftSection } from './LeftSection'
import { RightSection } from './RightSection'
import { Waves } from './Waves'

const useStyles = makeStyles(() => ({
	superContainer: {
		display: 'inline-flex',
		flexDirection: 'column',
		backgroundImage: `url(${Background})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		minHeight: '70vh',
		width: '100%',
	},
	innerContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		alignItems: 'center',
		paddingTop: '80px',
		// minHeight: '250px',
		minWidth: '200px',
		// backgroundColor: 'green',
	},
}))

export default function Hero() {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<NavbarBuffer />
			<Container className={classes.innerContainer}>
				<LeftSection />
				<RightSection />
			</Container>
			<Waves />
		</div>
	)
}
