import React, { lazy, Component } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
// import About from './pages/About'
// import Pricing from './pages/Pricing'
// import Contact from './pages/Contact'
// import Install from './pages/Install'
// import SignIn from './pages/SignIn'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import { createMuiTheme, LinearProgress, ThemeProvider } from '@material-ui/core/'
import { blue, orange } from '@material-ui/core/colors'
import { Suspense } from 'react'

const About = lazy(() => import('./pages/About'))
const Pricing = lazy(() => import('./pages/Pricing'))
const Contact = lazy(() => import('./pages/Contact'))
const Install = lazy(() => import('./pages/Install'))
const SignIn = lazy(() => import('./pages/SignIn'))

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: blue.A400,
		},
		secondary: {
			main: orange.A200,
		},
		background: {
			paper: 'rgb(245,245,245)',
			default: 'rgb(230,230,230)',
		},
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: '500px',
			},
		},
	},
})

class App extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Router>
					<Navbar root={this} />

					<Suspense
						fallback={
							<div style={{ height: '100vh' }}>
								<LinearProgress />
							</div>
						}
					>
						<Switch>
							<Route path='/signin'>
								<SignIn />
							</Route>
							<Route path='/about'>
								<About />
							</Route>
							<Route path='/pricing'>
								<Pricing />
							</Route>
							<Route path='/contact'>
								<Contact />
							</Route>
							<Route path='/install'>
								<Install />
							</Route>
							<Route path='/'>
								<Home />
							</Route>
						</Switch>
					</Suspense>

					<Footer />
				</Router>
			</ThemeProvider>
		)
	}
}

export default App
