import React from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import imageLeft from '../../../assets/images/section2-img.webp'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import { Link } from 'react-router-dom'
import { SingleWave } from '../Hero/Waves'

const useStyles = makeStyles(() => ({
	superContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		backgroundColor: '#F0F5F8',
		width: '100%',
		padding: '40px 0px 0px 0px',
	},
	columnLeft: {
		display: 'flex',
		minWidth: '300px',
		maxWidth: '550px',
		padding: '40px 24px 0px 24px',
	},
	columnRight: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '300px',
		maxWidth: '450px',
		padding: '40px 24px 0px 24px',
	},
	img: { width: '100%', objectFit: 'scale-down' },
	textTop: {
		fontSize: '18px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '500',
	},
	textMid: {
		marginBottom: '24px',
		fontSize: '48px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '700',
	},
	textBtm: {
		marginBottom: '24px',
		fontSize: '17px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '300',
	},
	textBullet: {
		display: 'flex',
		alignItems: 'center',
		color: '#282828',
		fontSize: '18px',
		fontFamily: "'Poppins', sans-serif",
		fontWeight: '500',
		marginBottom: '24px',
	},
	tick: {
		marginRight: '24px',
	},
	largeIcon: {
		width: '80px',
		height: '80px',
	},
	btn: {
		fontFamily: '"Poppins", Sans-serif',
		fontSize: '17px',
		fontWeight: '500',
		padding: '19px 40px 19px 40px',
		marginTop: '24px',
		marginBottom: '24px',
	},
}))

export default function TakeAdvantageOfYourData() {
	const classes = useStyles()

	return (
		<div className={classes.superContainer}>
			<div className={classes.columnLeft}>
				<img loading='lazy' src={imageLeft} className={classes.img} alt='decoration' />
			</div>
			<div className={classes.columnRight}>
				<Typography className={classes.textTop}>Track your stock</Typography>
				<Typography className={classes.textMid}>With Duffelbag</Typography>
				<Typography className={classes.textBtm}>
					With Duffelbag, you can keep track of you Inventory in smarter ways. Sort your
					stock by location, supplier, quantity, a custom identifier and more! Finally say
					goodbye to inefficient inventory management and boost your workflow today!
				</Typography>
				<div>
					<Typography className={classes.textBullet}>
						<CheckCircleRoundedIcon
							fontSize='large'
							className={classes.tick}
							color='primary'
						/>
						Easy Interface
					</Typography>

					<Typography className={classes.textBullet}>
						<CheckCircleRoundedIcon
							fontSize='large'
							className={classes.tick}
							color='primary'
						/>
						Affordable Price
					</Typography>

					<Typography className={classes.textBullet}>
						<CheckCircleRoundedIcon
							fontSize='large'
							className={classes.tick}
							color='primary'
						/>
						Inventory Statistics
					</Typography>

					<Button
						variant='contained'
						color='primary'
						component={Link}
						to='/pricing'
						className={classes.btn}
					>
						Get Started Now
					</Button>
				</div>
			</div>

			<SingleWave />
		</div>
	)
}
